<template>
  <div
    class="wrap-pass-animation"
    :class="{
      leave: leave,
    }"
  >
    <div
      ref="userCardAnimation"
      class="wrap-white-board"
      :class="{leave: leave, tsume: mode === 'tsume'}"
    >
      <b-row
        class="d-flex flex-column justify-content-center align-items-center h-100 position-relative wrap-user"
      >
        <div v-show="mode === 'game'" class="p-0 card-user">
          <b-card
            bg-variant="blackChess"
            text-variant="white"
            border-variant="blackChess"
            class="position-relative"
          >
            <div class="d-flex flex-nowrap align-items-center">
              <b-img
                class="avatar ml-4 mt-4 mb-4 mr-4"
                rounded="circle"
                width="80"
                height="80"
                :srcset="blackPlayerAvatar"
                alt="avatar"
              ></b-img>
              <p class="user-details w-100 m-0 text-20 font-weight-bold">
                {{
                  blackPlayer.nickName +
                  ' - ' +
                  (blackPlayer.level
                    ? 'Lv.' + blackPlayer.level
                    : blackPlayer.rankLabel)
                }}
              </p>
            </div>
          </b-card>
        </div>
        <b-img
          v-show="mode === 'game'"
          class="text-vs"
          width="97"
          :srcset="require('@/assets/img/practice/vs-primary.png?srcset')"
          alt="vs"
        ></b-img>
        <b-img
          v-show="mode === 'tsume'"
          class="text-start"
          width="444"
          height="188"
          :srcset="require('@/assets/img/practice/start.png?srcset')"
          alt="start"
        ></b-img>
        <div v-show="mode === 'game'" class="p-0 card-user">
          <b-card border-variant="white" class="card-shadow position-relative">
            <div class="d-flex flex-nowrap align-items-center">
              <b-img
                class="avatar ml-4 mt-4 mb-4 mr-4"
                rounded="circle"
                width="80"
                height="80"
                :srcset="whitePlayerAvatar"
                alt="avatar"
              ></b-img>
              <p class="user-details w-100 m-0 text-20 font-weight-bold">
                {{
                  whitePlayer.nickName +
                  ' - ' +
                  (whitePlayer.level
                    ? 'Lv.' + whitePlayer.level
                    : whitePlayer.rankLabel)
                }}
              </p>
            </div>
          </b-card>
        </div>
      </b-row>
    </div>
  </div>
</template>
<script>
import rankList from '@/json/rank.json';
import aiLevelMapping from '@/lib/base/aiLevel.js';
import aiCharacterMapping from '@/json/aiCharacter.json';

export default {
  name: 'PassAnimation',
  components: {},
  props: {
    leave: {
      type: Boolean,
      required: false,
      default: false,
    },
    mode: {
      type: String,
      default: 'game',
    },
    black: {
      type: Object,
      default: null,
    },
    white: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      rankList,
      aiCharacterMapping,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    userColor() {
      return this.gameData.userColor;
    },
    blackPlayer() {
      if (this.black) {
        return {
          nickName: this.black.name || this.black.nickName,
          rankLabel: rankList[this.black.rank].label,
          avatar: this.black.avatar,
        };
      }
      if (this.userColor === 'black') {
        const userData = this.user.userData;
        userData.rankLabel = rankList[this.user.userData.rank].label;
        return userData;
      } else {
        // 防止prettier自動換行產生空白
        // prettier-ignore
        return {
          nickName: this.gameData.opponentName,
          rankLabel: aiLevelMapping.processAiLevel(this.gameData.aiLevel),
          level: this.gameData.aiId || null,
          avatar: this.gameData.createdTime && this.gameData.opponentName !== '黑小嘉'
            ? `avatar_tsumego_ai_${aiCharacterMapping[this.gameData.opponentName] || 1}`
            : 'ai_b',
        };
      }
    },
    whitePlayer() {
      if (this.white) {
        return {
          nickName: this.white.name || this.white.nickName,
          rankLabel: rankList[this.white.rank].label,
          avatar: this.white.avatar,
        };
      }
      if (this.userColor === 'white') {
        const userData = this.user.userData;
        userData.rankLabel = rankList[this.user.userData.rank].label;
        return userData;
      } else {
        // 防止prettier自動換行產生空白
        // prettier-ignore
        return {
          nickName: this.gameData.opponentName,
          rankLabel: aiLevelMapping.processAiLevel(this.gameData.aiLevel),
          level: this.gameData.aiId || null,
          avatar: this.gameData.createdTime && this.gameData.opponentName !== '白小嘉'
            ? `avatar_tsumego_ai_${aiCharacterMapping[this.gameData.opponentName] || 1}`
            : 'ai_w',
        };
      }
    },
    whitePlayerAvatar() {
      return require(`@/assets/img/personal/avatar/${this.whitePlayer.avatar}.png?srcset`);
    },
    blackPlayerAvatar() {
      return require(`@/assets/img/personal/avatar/${this.blackPlayer.avatar}.png?srcset`);
    },
    gameData() {
      return this.$store.state.aiGame;
    },
    deviceRatio() {
      const width = this.$store.state.env.width;
      const height = this.$store.state.env.height;
      return height / width;
    },
  },
  created() {
    this.$playSound('transition');
  },
  mounted() {
    this.$refs.userCardAnimation.addEventListener(
      'animationend',
      this.userCardAnimationHandler
    );
  },
  methods: {
    userCardAnimationHandler(event) {
      if (event.animationName.includes('userCardOpacity')) {
        this.$emit('animation-end');
        this.$refs.userCardAnimation.removeEventListener(
          'animationend',
          this.userCardAnimationHandler
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-pass-animation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1021;
  background: $grayscale-20;
  .wrap-white-board {
    width: 500px;
    height: 500px;
    border-radius: 50px;
    position: absolute;
    top: calc(50% - 250px);
    left: calc(50% - 250px);
    background: $bgsection;
    border: 20px solid white;
    animation: zoomIn 0.3s forwards;
    &.leave {
      animation: userCardOpacity 0.5s linear 1s forwards;
    }
    &.tsume {
      background: transparent;
      border: 20px solid transparent;
    }
  }
  @keyframes zoomIn {
    from {
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
      opacity: 1;
    }
  }
  @keyframes userCardOpacity {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  .wrap-user {
    z-index: 1;
    .text-vs {
      opacity: 0;
      margin: 34px 0;
      animation: vsMoveOn 0.1s ease 0.4s forwards;
    }
    .text-start {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
      animation: bounceIn calc(1s * 0.75) 0.3s forwards;
    }
    .card-user {
      width: 280px;
      &:first-of-type {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
        animation: bounceIn calc(1s * 0.75) 0.7s forwards;
      }
      &:last-of-type {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
        animation: bounceIn calc(1s * 0.75) 0.6s forwards;
      }
      @keyframes bounceIn {
        from,
        20%,
        40%,
        60%,
        80%,
        to {
          -webkit-animation-timing-function: cubic-bezier(
            0.215,
            0.61,
            0.355,
            1
          );
          animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
          opacity: 0;
          -webkit-transform: scale3d(0.3, 0.3, 0.3);
          transform: scale3d(0.3, 0.3, 0.3);
        }

        20% {
          -webkit-transform: scale3d(1.1, 1.1, 1.1);
          transform: scale3d(1.1, 1.1, 1.1);
        }

        40% {
          -webkit-transform: scale3d(0.9, 0.9, 0.9);
          transform: scale3d(0.9, 0.9, 0.9);
        }

        60% {
          opacity: 1;
          -webkit-transform: scale3d(1.03, 1.03, 1.03);
          transform: scale3d(1.03, 1.03, 1.03);
        }

        80% {
          -webkit-transform: scale3d(0.97, 0.97, 0.97);
          transform: scale3d(0.97, 0.97, 0.97);
        }

        to {
          opacity: 1;
          -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
        }
      }
      @keyframes vsMoveOn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      @keyframes startMoveOn {
        from {
          opacity: 0;
          transform: translateX(-20px);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
      .card {
        border-radius: $rounded-lg;
        box-shadow: -30px 30px 3px 0 $grayscale-20;
        border: none;
        .user-details {
          max-width: 144px;
        }
      }
    }
  }
}
</style>
