<template>
  <div class="modal-result-wrapper">
    <modal-wrapper :is-modal-wrapper-show="true">
      <div
        class="modal-result position-relative d-flex flex-column justify-content-center align-items-center"
        :class="{'jungo-modal-result': $isJungo}"
        @click.stop
      >
        <div class="position-relative">
          <b-img
            class="img-result mt-4"
            :srcset="resultImages"
            alt="result"
          ></b-img>
          <span
            class="result-state text-white font-weight-bold position-absolute"
            :class="{reviewed: !hasReviewed && mode.indexOf('Game') >= 0}"
          >
            {{ resultState }}
          </span>
        </div>
        <p
          class="result-content mt-3 mb-2 text-primary font-weight-bold text-center"
        >
          {{ winningWayText ? winningWayText : resultContent | winningWayText }}
        </p>
        <div v-if="mode === 'practiceGame'" class="wrap-btn">
          <div class="d-flex justify-content-center align-items-center">
            <b-button
              variant="secondary"
              :size="$device.isMobile ? 'md' : 'lg'"
              class="return"
              @click="onItemClick('return')"
            >
              {{ $t('返回') }}
            </b-button>
            <b-button
              v-if="!$isJungo"
              variant="infoAndLink"
              :size="$device.isMobile ? 'md' : 'lg'"
              :disabled="!hasReviewed"
              class="ml-3 text-white"
              @click="onItemClick('reviewGame')"
            >
              {{ $t('覆盤') }}
            </b-button>
          </div>
          <p
            v-if="!$isJungo && !hasReviewed"
            class="notice-reviewed mt-2 mb-0 text-center"
          >
            {{ $t('此局手數未達20無法進行覆盤') }}
          </p>
        </div>
        <div v-if="mode === 'pvp'" class="wrap-btn">
          <div class="d-flex justify-content-center align-items-center">
            <b-button
              :variant="$isJungo ? 'primary' : 'secondary'"
              :size="$device.isMobile ? 'md' : 'lg'"
              class="mr-3 return"
              @click="onItemClick('return')"
            >
              {{ $t('返回') }}
            </b-button>
            <b-button
              :variant="$isJungo ? 'secondary' : 'primary'"
              :size="$device.isMobile ? 'md' : 'lg'"
              @click="onItemClick('rematch')"
            >
              {{ $t('邀請再一局') }}
            </b-button>
            <b-button
              v-if="!$isJungo"
              variant="infoAndLink"
              :size="$device.isMobile ? 'md' : 'lg'"
              :disabled="!hasReviewed"
              class="ml-3 text-white"
              @click="onItemClick('reviewGame')"
            >
              {{ $t('覆盤') }}
            </b-button>
          </div>
          <p
            v-if="!hasReviewed && !$isJungo"
            class="notice-reviewed mt-2 mb-0 text-center"
          >
            {{ $t('此局手數未達20無法進行覆盤') }}
          </p>
        </div>
        <div
          v-if="mode === 'courseGame' || mode === 'courseTsume'"
          class="wrap-btn"
        >
          <div class="d-flex justify-content-center align-items-center">
            <b-button
              variant="secondary"
              :size="$device.isMobile ? 'md' : 'lg'"
              @click="onItemClick('return')"
            >
              {{ $t('返回') }}
            </b-button>
            <b-button
              v-if="mode === 'courseTsume'"
              variant="primary"
              :size="$device.isMobile ? 'md' : 'lg'"
              class="ml-3"
              @click="onItemClick('answer')"
            >
              {{ $t('看解答') }}
            </b-button>
            <b-button
              v-if="mode === 'courseGame'"
              :variant="$isJungo ? 'accent-2' : 'infoAndLink'"
              :size="$device.isMobile ? 'md' : 'lg'"
              :disabled="!hasReviewed"
              class="ml-3 text-white"
              @click="onItemClick('reviewGame')"
            >
              {{ $t('覆盤') }}
            </b-button>
          </div>
          <p
            v-if="!hasReviewed && mode === 'courseGame'"
            class="notice-reviewed mt-2 mb-0 text-center"
          >
            {{ $t('此局手數未達20無法進行覆盤') }}
          </p>
        </div>
        <div
          v-else-if="mode === 'practiceTsume'"
          class="wrap-btn d-flex justify-content-center align-items-center"
        >
          <b-button
            variant="secondary"
            :size="$device.isMobile ? 'md' : 'lg'"
            class="mr-3"
            @click="onItemClick('return')"
          >
            {{ $t('返回') }}
          </b-button>
          <b-button
            variant="primary"
            :size="$device.isMobile ? 'md' : 'lg'"
            class="mr-3"
            @click="onItemClick('answer')"
          >
            {{ $t('看解答') }}
          </b-button>
        </div>
        <div
          v-else-if="
            mode === 'verificationTsume' || mode === 'verificationGame'
          "
          class="wrap-btn d-flex justify-content-center align-items-center"
        >
          <b-button
            :variant="$isJungo ? 'secondary' : 'primary'"
            :size="$device.isMobile ? 'md' : 'lg'"
            @click="onItemClick('confirm')"
          >
            {{ $t('確定') }}
          </b-button>
        </div>
      </div>
    </modal-wrapper>
  </div>
</template>

<script>
import ModalWrapper from '@/components/Base/ModalWrapper.vue';
export default {
  components: {
    ModalWrapper,
  },
  props: {
    mode: {
      type: String,
      default: '',
      validator: (val) => {
        return [
          'practiceGame',
          'practiceTsume',
          'verificationTsume',
          'verificationGame',
          'courseGame',
          'courseTsume',
          'pvp',
          '',
        ].includes(val);
        // 下棋練習、做題練習、檢定、課程練習、課程做題、人人對弈
      },
    },
    isSuccess: {
      type: Boolean,
      default: false,
    },
    isOverTime: {
      type: Boolean,
      default: false,
    },
    resultContent: {
      type: String,
      default: '',
    },
    winningWayText: {
      type: String,
      default: null,
    },
    hasReviewed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalShow: false,
    };
  },
  computed: {
    resultImages() {
      if (['verificationTsume', 'verificationGame'].includes(this.mode)) {
        return require('@/assets/img/modal/complete.png?srcset');
      } else if (this.isSuccess) {
        return require('@/assets/img/modal/awesome.png?srcset');
      } else {
        return require('@/assets/img/modal/frustration.png?srcset');
      }
    },
    resultState() {
      if (['verificationTsume', 'verificationGame'].includes(this.mode)) {
        return this.isOverTime ? this.$t('作答結束') : this.$t('完成');
      } else if (['practiceGame', 'courseGame', 'pvp'].includes(this.mode)) {
        return this.isSuccess ? this.$t('勝利') : this.$t('失敗');
      } else {
        return this.isSuccess ? this.$t('很熟練喔') : this.$t('要再加強');
      }
    },
  },
  methods: {
    onItemClick(value) {
      this.$emit('item-click', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-result {
  background: $bgsection;
  border-radius: 30px;
  width: 300px;
  min-height: 374px;
  @media screen and (min-width: 768px) {
    width: 390px;
  }
  .result-content {
    font-size: 20px;
    line-height: 34px;
    @media screen and (min-width: 768px) {
      font-size: 28px;
      line-height: 44px;
    }
  }
  .wrap-btn {
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background: white;
    padding: 16px 0;
    .btn-infoAndLink:disabled,
    .btn-infoAndLink.disabled {
      background: #ced4da;
      border-color: #ced4da;
    }
    .notice-reviewed {
      font-size: 12px;
      color: $font-grayscale-1;
      @media screen and (min-width: 768px) {
        font-size: 14px;
      }
    }
  }
  .img-result {
    width: 230px;
    height: 230px;
    @media screen and (min-width: 768px) {
      width: 320px;
      height: 320px;
    }
  }
  .result-state {
    bottom: 4.5%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: 28px;
    @media screen and (min-width: 768px) {
      font-size: 38px;
    }
  }
}
</style>
